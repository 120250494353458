import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/home/HomeIndex.vue')
    },
    {
        path: '/accsess-denied',
        name: 'accsess-denied',
        component: () => import('@/views/system/error/NoLogin.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/home/login/Login.vue')
    },
    {
        path: '/apprelease/download',
        name: 'appreleaseDownload',
        component: () => import('@/views/projects/appsrelease/appsdownload.vue')
    },
    {path: '/doregister', name: 'doregister', component: () => import('@/views/home/register/Register.vue')},
    {path: '/forgetpwd', name: 'forgetpwd', component: () => import('@/views/home/login/ForgetPwd.vue')},
    //本地部署
    {
        path: '/download-location',
        name: 'download-location',
        component: () => import('@/views/home/download/LocationDownload.vue')
    },
    {
        path: '/vmcode-download',
        name: 'vmcode-download',
        component: () => import('@/views/home/download/vmcodeDownload.vue')
    },
    {
        path: '/syshelp',
        name: 'syshelp',
        component: () => import('@/views/system/syshelp/SyshelpMain.vue')
    },
    // 系统帮助
    {path: '/helpCenter', name: 'HelpCenter', component: () => import('@/views/system/syshelp/SyshelpMain.vue')},//缺陷添加或修改页
    {path: '/voffceiview', name: 'voffceiview', component: () => import('@/components/FilePreviewer/VueOfficePreviewer.vue')},
    {path: '/services', name: 'services', component: () => import('@/views/system/service/Services.vue')},
    {path: '/privacy', name: 'privacy', component: () => import('@/views/system/service/Privacy.vue')},
    {
        path: '/index',
        name: 'home',
        component: () => import('@/views/workbench/WorkBench.vue'),
        redirect: '/home',
        children: [
            {path: '/home', name: 'homes', component: () => import('@/views/dashboard/RightSidebar.vue')},
            //缺陷相关
            {
                path: '/my-bugsinfo-list',
                name: 'MyBugsinfoList',
                component: () => import('@/views/projects/My/MyBugsinfoList.vue')
            },//我的缺陷信息
            {
                path: '/my-project-manage',
                name: 'MyProjectManage',
                component: () => import('@/views/projects/My/MyProjectManage.vue')
            },//我的项目信息
            {
                path: '/my-task-manage',
                name: 'MyTaskManage',
                component: () => import('@/views/projects/My/MyTaskManager.vue')
            },//我的任务信息
            {path: '/project-bugs-manage', name: 'bugsList', component: () => import('@/views/projects/bugs/BugsList.vue')},//缺陷列表页
            {path: '/bugsdetails', name: 'BugsDetails', component: () => import('@/views/projects/bugs/BugsDetails.vue')},//缺陷详情页
            {path: '/bugsmod', name: 'BugsMod', component: () => import('@/views/projects/bugs/BugsMod.vue')},//缺陷添加或修改页
            //项目成员相关
            {
                path: '/config-project-member',
                name: 'projectMemberManage',
                component: () => import('@/views/projects/member/ProjectMemberManage.vue')
            },//项目成员列表
            {
                path: '/project-member-manage',
                name: 'pmUserProjectList',
                component: () => import('@/views/projects/member/ProjectMemberHome.vue'),//项目干系人管理
                children: [
                    {
                        path: '/projectmember-invite-phone',
                        name: 'ProjectMember-Invite-phone',
                        component: () => import('@/views/projects/member/ProjectMember-Invite-phone.vue')
                    },//邀请人-手机号形式
                    {
                        path: '/projectmember-invite-url',
                        name: 'ProjectMember-Invite-url',
                        component: () => import('@/views/projects/member/ProjectMember-Invite-url.vue')
                    },//邀请人-链接形式
                    {
                        path: '/projectmember-invite-qrcode',
                        name: 'ProjectMember-Invite-qrcode',
                        component: () => import('@/views/projects/member/ProjectMember-Invite-qrcode.vue')
                    },//邀请人-二维码形式
                    {
                        path: '/projectmember-invite-email',
                        name: 'ProjectMember-Invite-email',
                        component: () => import('@/views/projects/member/ProjectMember-Invite-email.vue')
                    },//邀请人-Email形式
                ]
            },
            {
                path: '/updatePmUserProjectPage',
                name: 'updatePmUserProjectPage',
                component: () => import('@/views/projects/member/ProjectGroup-Add.vue')
            },//邀请或修改项目小组成员
            {
                path: '/projectgroup-view',
                name: 'ProjectGroup-View',
                component: () => import('@/views/projects/member/ProjectGroup-View.vue')
            },//查看项目小组成员
            {
                path: '/teamgroupmemberlist',
                name: 'TeamGroupMemberList',
                component: () => import('@/views/projects/member/TeamGroupMemberList.vue')
            },//查看项目干系人
            //项目模块相关
            {
                path: '/project-module-manage',
                name: 'moduleList',
                component: () => import('@/views/projects/module/ModuleList.vue')
            },//模块列表

            // 项目文档
            {
                path: '/project-docs-manage',
                name: 'DocsList',
                component: () => import('@/views/projects/docs/ProjectDocsList.vue')
            },
            {path: '/docsmod', name: 'DocsMod', component: () => import('@/views/projects/docs/ProjectDocsMod.vue')},
            {
                path: '/docsdetail',
                name: 'DocsDetail',
                component: () => import('@/views/projects/docs/ProjectDocsDetail.vue')
            },
            // 数据库相关
            {
                path: '/project-database-manage',
                name: 'DatabaseList',
                component: () => import('@/views/projects/database/ProjectDatabaseList.vue')
            },//数据库列表页
            {
                path: '/databasemod',
                name: 'DatabaseMod',
                component: () => import('@/views/projects/database/ProjectDatabaseMod.vue')
            },
            {
                path: '/databasedesign',
                name: 'DatabaseDesign',
                component: () => import('@/views/projects/database/ProjectDatabaseDesign.vue')
            },
            {
                path: '/databaseoperation',
                name: 'DatabaseOperation',
                component: () => import('@/views/projects/database/ProjectDatabaseOperation.vue')
            },

            //管理
            {
                path: '/managemod', name: 'ManagementMod', component: () => import('@/views/management/ManageMain.vue'),
                redirect: '/projectdetail',
                children: [
                    //左侧栏
                    //统计
                    {
                        path: '/bugsreport',
                        name: 'BugsReportMain',
                        component: () => import('@/views/management/report/BugsReportMain.vue'),
                        children: [
                            {
                                path: '/bugsolved-report',
                                name: 'BugSolvedReport',
                                component: () => import('@/views/management/report/BugSolvedReport.vue')
                            },
                            {
                                path: '/bugsubmit-report',
                                name: 'BugSubmitReport',
                                component: () => import('@/views/management/report/BugSubmitReport.vue')
                            },
                            {
                                path: '/bugmain-report',
                                name: 'BugMainReport',
                                component: () => import('@/views/management/report/BugsAllReport.vue')
                            }
                        ]
                    },
                    {
                        path: '/projectdetail',
                        name: 'ProjectDetail',
                        component: () => import('@/views/mprojects/ProjectDetail.vue')
                    },
                    {
                        path: '/project-manage-weeklyreport',
                        name: 'WeekReportList2',
                        component: () => import('@/views/projects/plans/WeekReportList.vue')
                    },
                ]
            },
            //产品
            {path: '/product-manage', name: 'ProductList', component: () => import('@/views/product/ProductList.vue')},
            {path: '/productmod', name: 'ProductMod', component: () => import('@/views/product/ProductMod.vue')},
            {path: '/productdetail', name: 'ProductDetail', component: () => import('@//views/product/ProductDetail.vue')},

            //项目管理
            {path: '/mproject-manage', name: 'ProjectList', component: () => import('@/views/mprojects/ProjectList.vue')},
            {path: '/mprojectmod', name: 'ProjectMod', component: () => import('@/views/mprojects/ProjectMod.vue')},
            {
                path: '/accountsummary',
                name: 'AccountSummary',
                component: () => import('@/views/summary/AccountSummary.vue'),
                redirect: '/userinfo2details',
                children: [
                    {
                        path: '/userinfo2details',
                        name: 'userinfo2',
                        component: () => import('@/views/account/baseinfo/UserDetail.vue')
                    },
                    {
                        path: '/orginfo2details',
                        name: 'OrgInfo2',
                        component: () => import('@/views/account/org/OrgInfo.vue')
                    },
                    {path: '/orginfo2Orginfo', name: 'OrgInfo3', component: () => import('@/views/summary/OrgInfo.vue')},
                ]
            },

            // 帮助文档
            {
                path: '/project-help-docs',
                name: 'HelpDocsList',
                component: () => import('@/views/projects/docs/HelpDocsList.vue')
            },
            {path: '/help-docs-mod', name: 'HelpDocsMod', component: () => import('@/views/projects/docs/HelpDocsMod.vue')},

            //思维导图
            {
                path: '/project-mindmap-manage',
                name: 'MindMapList',
                component: () => import('@/views/projects/mindmap/ProjectMindMapList.vue')
            },
            {
                path: '/mindmap-mod',
                name: 'MindMapMod',
                component: () => import('@/views/projects/mindmap/ProjectMindMapMod.vue')
            },

            //项目测试用例
            {
                path: '/project-testcase-manage',
                name: 'projectTestcaseManage',
                component: () => import('@/views/projects/testcase/TestCaseList.vue')
            },//测试用例管理
            {
                path: '/testcasedetail',
                name: 'testcaseDetail',
                component: () => import('@/views/projects/testcase/TestCaseDetail.vue')
            },//测试用例详情页
            {
                path: '/testcasedetail1',
                name: 'testcaseDetail1',
                component: () => import('@/views/projects/testcase/TestCaseDetail1.vue')
            },//测试用例详情页
            {
                path: '/testcasedetail2',
                name: 'testcaseDetail2',
                component: () => import('@/views/projects/testcase/TestCaseDetail2.vue')
            },//测试用例详情页
            {
                path: '/go-add-testcase-home',
                name: 'goAddTestcaseHome',
                component: () => import('@/views/projects/testcase/TestcaseMod.vue')
            },//测试用例添加

            // 项目计划
            {
                path: '/project-plans-manage',
                name: 'PlansList',
                component: () => import('@/views/projects/plans/PlanList.vue')
            },
            {path: '/plan-gantt', name: 'PlanMod', component: () => import('@/views/projects/plans/PlanGantt.vue')},
            // 项目周报
            {
                path: '/project-weekreport-manage',
                name: 'WeekReportList',
                component: () => import('@/views/projects/plans/WeekReportList.vue')
            },
            {
                path: '/weekreportmod',
                name: 'WeekReportMod',
                component: () => import('@/views/projects/plans/WeekReportMod.vue')
            },

            //项目需求相关
            {
                path: '/project-requestment-manage',
                name: 'requirementList',
                component: () => import('@/views/projects/requirements/RequirementList.vue')
            },
            {
                path: '/requestment/mod',
                name: 'requirementMod',
                component: () => import('@/views/projects/requirements/RequirementMod.vue')
            },
            {
                path: '/requestment/details',
                name: 'requirementDetails',
                component: () => import('@/views/projects/requirements/RequirementDetails.vue')
            },

            //账号相关
            {path: '/usermod', name: 'usermod', component: () => import('@/views/account/baseinfo/UserDetailMod.vue')},
            {
                path: '/accountinfo',
                name: 'userinfomain',
                component: () => import('@/views/account/baseinfo/UserInfoMain.vue'),
                redirect: '/userinfo/details',
                children: [
                    {
                        path: '/userinfo/details',
                        name: 'userinfo',
                        component: () => import('@/views/account/baseinfo/UserDetail.vue')
                    },
                    {
                        path: '/userinfo/identity',
                        name: 'identity',
                        component: () => import('@/views/account/baseinfo/UserIdentity.vue')
                    },
                    {
                        path: '/userinfo/vipinfo',
                        name: 'vipinfo',
                        component: () => import('@/views/account/baseinfo/UserVipInfo.vue')
                    },
                    {
                        path: '/userinfo/personal',
                        name: 'personal',
                        component: () => import('@/views/account/baseinfo/Personal.vue')
                    },
                    {
                        path: '/userinfo/createdorglist',
                        name: 'createOrgInfo',
                        component: () => import('@/views/account/baseinfo/MyCreatedOrginfo.vue')
                    },
                    {
                        path: '/userinfo/joinedorglist',
                        name: 'jionorginfo',
                        component: () => import('@/views/account/baseinfo/MyJoindOrgInfo.vue')
                    },
                    {
                        path: '/userinfo/invitedorglist',
                        name: 'inviteorginfo',
                        component: () => import('@/views/account/baseinfo/MyInvitedOrginfo.vue')
                    },
                    {
                        path: "/userinfo/notify",
                        name: 'notify',
                        component: () => import('@/views/account/baseinfo/MyNotify.vue')
                    }
                ]
            },
            {path: '/org-add', name: 'orgAdd', component: () => import('@/views/account/org/OrgAdd.vue')},
            {path: '/VipView', name: 'VipView', component: () => import('@/views/account/vippay/VipView.vue')},
            {path: '/VipOrder', name: 'VipOrder', component: () => import('@/views/account/vippay/VipOrder.vue')},
            {path: '/UserLogout', name: 'UserLogout', component: () => import('@/views/account/logout/UserLogout.vue')},
            //知识分类模块
            {
                path: '/techblog-home',
                name: 'blogshome',
                component: () => import('@/views/blogs/BlogsHome.vue'),
                children: []
            },
            {path: '/config-blogstype', name: 'blogkeylist', component: () => import('@/views/config/BlogKeyList.vue')},
            {path: '/techAdd', name: 'techAdd', component: () => import('@/views/blogs/TechAdd.vue')},
            {path: '/blogsAllList', name: 'blogsAllList', component: () => import('@/views/blogs/BlogsAllList.vue')},
            {path: '/blogsShow', name: 'blogsShow', component: () => import('@/views/blogs/BlogsShow.vue')},
            {path: '/blogsHome', name: 'blogsHome', component: () => import('@/views/blogs/BlogsHome.vue')},
            {path: '/blogsAdd', name: 'blogsAdd', component: () => import('@/views/blogs/BlogsMod.vue')},
            //配置模块
            {path: '/config-docstype', name: 'docstypelist', component: () => import('@/views/config/DocsTypeList.vue')},
            {path: '/config-bugstype', name: 'bugstypelist', component: () => import('@/views/config/BugsTypeList.vue')},
            {path: '/config-bugsscore', name: 'bugsscore', component: () => import('@/views/config/BugsScore.vue')},
            {path: '/bugstypemod', name: 'bugstypemod', component: () => import('@/views/config/BugsTypeMod.vue')},
            {path: '/BugsScoreList', name: 'BugsScoreList', component: () => import('@/views/config/BugsScoreList.vue')},
            // {path: '/BugsScoreAddOrUpdate',name: 'BugsScoreAddOrUpdate',component: () => import('@/views/config/BugsScoreUpdateMode')},
            //日常工作
            {path: '/dailywork', name: 'dailywork', component: () => import('@/views/dailywork/DailyWork.vue')},
            //仪表盘
            {path: '/workbench-home', name: 'workbench', component: () => import('@/views/dashboard/MyWorkBench.vue')},
            //文档模块
            // {path: '/project-docs-manage', name: 'docslist', component: () => import('@/views/projects/docs/DocsList')},
            // {path: '/docsmod', name: 'docsmod', component: () => import('@/views/projects/docs/DocsMod')},
            // {path: '/docsdetail', name: 'docsdetail', component: () => import('@/views/projects/docs/DocsDetail')},
            //未登陆
            {path: '/accsess-denied', name: 'NoLogin', component: () => import('@/views/system/error/NoLogin.vue')},
            //管理模块
            {
                path: '/MIndex', name: 'MIndex', component: () => import('@/views/management/MIndex.vue'),
                children: [
                    {path: '/maccount', name: 'Maccount', component: () => import('@/views/management/Maccount.vue')},
                    {path: '/mproject', name: 'Mproject', component: () => import('@/views/management/MProject.vue')},
                    {path: '/mtaks', name: 'Mtaks', component: () => import('@/views/management/MTaks.vue')},
                    {path: '/mbugs', name: 'Mbugs', component: () => import('@/views/management/MlsBugs.vue')},
                    {path: '/mdocs', name: 'Mdocs', component: () => import('@/views/management/MDocs.vue')},
                ]
            },
            //笔记
            {path: '/notes-home', name: 'NoteIndex', component: () => import('@/views/notes/NoteIndex.vue')},
            //组织模块
            {
                path: '/orginfo', name: 'OrgMod', component: () => import('@/views/account/org/OrgMod.vue'),
                redirect: '/orginfo/details',
                children: [
                    {path: '/orginfo/details', name: 'OrgInfo', component: () => import('@/views/account/org/OrgInfo.vue')},
                    {
                        path: '/orginfo/myorgright',
                        name: 'OrgRight',
                        component: () => import('@/views/account/org/OrgRight.vue')
                    },
                    {
                        path: '/orginfo/myprojectright',
                        name: 'ProjectRight',
                        component: () => import('@/views/account/org/ProjectRight.vue')
                    },
                    {
                        path: '/orginfo/deptlist',
                        name: 'DeptList',
                        component: () => import('@/views/account/org/DeptList.vue')
                    },
                    {
                        path: '/orginfo/employeelist',
                        name: 'UserList',
                        component: () => import('@/views/account/org/Member/MemberListView.vue')
                    },
                    {
                        path: '/orginfo/configroles',
                        name: 'RoleRightOrg',
                        component: () => import('@/views/account/org/RoleRightOrg.vue')
                    },
                    {
                        path: '/orginfomod',
                        name: 'OrgInfoMod',
                        component: () => import('@/views/account/org/OrgInfoMod.vue')
                    },
                    //项目角色和权限管理
                    {
                        path: '/config-project-roleright',
                        name: 'roleright_project',
                        component: () => import('@/views/account/org/RolerightProject.vue')
                    },
                ]
            },
            {path: '/member-mod', name: 'memberMod', component: () => import('@/views/account/org/Member/MemberModView.vue')},
            //系统模块
            {path: '/files-home', name: 'Files', component: () => import('@/views/oafiles/OaFiles.vue')},
            {path: '/email-home', name: 'EmailManage', component: () => import('@/views/email/EmailManage.vue')},

            //系统工具模块
            {path: '/tools-md5', name: 'Md5', component: () => import('@/views/devtools/Md5.vue')},
            {path: '/tools-pdfToWord', name: 'PdfToWord', component: () => import('@/views/devtools/PdfToWord.vue')},
            {path: '/tools-durid', name: 'Druid', component: () => import('@/views/devtools/DruidPass.vue')},
            {path: '/tools-qrcode', name: 'QrCode', component: () => import('@/views/devtools/QrCode.vue')},
            {path: '/tools-json', name: 'JSONUtil', component: () => import('@/views/devtools/JsonUtil.vue')},
            {path: '/tools-jscrypto', name: 'JSCrypto', component: () => import('@/views/devtools/CryptoJsCode.vue')},
            {path: '/tools-ascii', name: 'ASCII', component: () => import('@/views/devtools/ASCII.vue')},
            {path: '/tools-regex-template', name: 'Regex', component: () => import('@/views/devtools/RegexTemplate.vue')},
            {path: '/tools-binary', name: 'BinaryH', component: () => import('@/views/devtools/BinaryH.vue')},
            {path: '/tools-pdfToPng', name: 'PdfToPng', component: () => import('@/views/devtools/PdfToPng.vue')},
            {path: '/tools-pngToIcon', name: 'IconUtil', component: () => import('@/views/devtools/IconUtil.vue')},
            {path: '/tools-redis', name: 'RedisUtil', component: () => import('@/views/devtools/RedisUtil.vue')},
            {path: '/tools-extNetIp', name: 'GetExtNetIp', component: () => import('@/views/devtools/GetExtNetIp.vue')},

            //项目任务模块
            {path: '/config-tasktype', name: 'tasktypelist', component: () => import('@/views/config/TaskTypeList.vue')},
            {
                path: '/project-tasks-manage',
                name: 'taskList',
                component: () => import('@/views/projects/tasks/TaskList.vue')
            },
            {path: '/project/task/mod', name: 'taskMod', component: () => import('@/views/projects/tasks/TaskMod.vue')},
            {
                path: '/project/task/details',
                name: 'taskDetails',
                component: () => import('@/views/projects/tasks/TaskDetails.vue')
            },
            {path: '/tasktypemod', name: 'TaskTypeMod', component: () => import('@/views/config/TaskTypeMod.vue')},

            //节假日管理
            {
                path: '/HolidaysAddOrUpdate',
                name: 'HolidaysAddOrUpdate',
                component: () => import('@/views/config/holidays/HolidaysAddOrUpdate.vue')
            },
            {
                path: '/WorkDayAddOrUpdate',
                name: 'WorkDayAddOrUpdate',
                component: () => import('@/views/config/holidays/WorkDayAddOrUpdate.vue')
            },
            {
                path: '/orgmod',
                name: 'orgmod',
                component: () => import('@/views/config/holidays/Holidays-Mod.vue'),
                redirect: '/config-holidys',
                children: [
                    {
                        path: '/config-holidys',
                        name: 'holidays',
                        component: () => import('@/views/config/holidays/HolidaysMain.vue')
                    },
                    {path: '/WorkDay', name: 'WorkDay', component: () => import('@/views/config/holidays/WorkDay.vue')},
                ]
            },

            //报文相关
            {
                path: '/project-message-manage',
                name: 'messageList',
                component: () => import('@/views/projects/message/MessageList.vue')
            },

            //project--部署
            {
                path: '/project-deploy-manage',
                name: 'DeployList',
                component: () => import('@/views/projects/deploy/DeployList.vue')
            },
            {
                path: '/project-deploy-details',
                name: 'deployDetails',
                component: () => import('@/views/projects/deploy/DeployDetails.vue')
            },

            //SQL
            {path: '/project-sqls-manage', name: 'sqlList', component: () => import('@/views/projects/sql/SqlList.vue')},

            // 应用发布
            {
                path: '/project-appsrelease-manage',
                name: 'appsreleaseManage',
                component: () => import('@/views/projects/appsrelease/appsIndex.vue')
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_URL),
    routes
})
//登陆，注册，忘记密码，主页，下载本地，在线预览，服务，隐私，帮助，提交下载，未登录
const noNeedLoginPage = ['login', 'doregister', 'forgetpwd', 'index', 'download-location',
    'voffceiview', 'services', 'privacy', 'helpCenter', 'vmcode-download', 'accsess-denied',"syshelp","/apprelease/download"]
router.beforeEach((to, from, next) => {
    let userStr = sessionStorage.getItem("mlpmisuser")
    if (noNeedLoginPage.includes(to.name)) {
        next()
        return
    }
    if (userStr) {
        try {
            if (sessionStorage.getItem('isrtoken')) {
                next()
            } else {
                next('/login')
            }
        } catch (err) {
            // //console.error(err)
            next('/login')
        }
    } else {
        next('/login')
    }
})
export default router

import {createApp} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import '@arco-design/web-vue/dist/arco.css'
import {createPinia} from "pinia";
import './assets/css/global.css'

const app = createApp(App)
const pinia = createPinia()
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key])
});
app.use(router)
    .use(ElementPlus, {locale: zhCn})
    .use(pinia)
    .mount('#app')